import './css/index.css';
import 'components/footer';
import 'components/card-article';
import 'components/card-event';
import 'components/card-featured-data';
import 'components/group-cards-mini-research';
import 'components/featured-promo';
import 'components/strip-callout';
import 'components/notification';
import Mediator from 'js/mediator';
import Navigation from 'components/navigation';

Mediator.create( {

    componentClasses: [
        Navigation
    ],

    ready() {

        this.featuredWrapper = null;
        this.featuredRegion = null;
        this.featuredControl = null;
        this.cardsWrapper = document.querySelector( '.cards' );
        this.moreCardRows = document.querySelectorAll( '.cards .card-row:nth-child( 2 ) ~ .card-row' );

        this.setupFeaturedContentHTML();

    },

    setupFeaturedContentHTML() {

        if ( !this.moreCardRows.length ) return;

        const fragment = document.createDocumentFragment();

        const wrapper = document.createElement( 'div' );

        wrapper.className = 'more-rows-wrapper';

        const region = document.createElement( 'div' );

        region.id = 'more-rows-region';
        region.setAttribute( 'role', 'region' );
        region.setAttribute( 'tabindex', -1 );
        region.setAttribute( 'aria-labelledby', 'more-rows-control' );

        const control = document.createElement( 'button' );

        control.type = 'button';
        control.id = 'more-rows-control';
        control.textContent = 'More featured content';
        control.setAttribute( 'aria-expanded', false );
        control.setAttribute( 'aria-controls', region.id );

        fragment.appendChild( wrapper );
        [ ...this.moreCardRows ].forEach( ( el ) => region.appendChild( el ) );
        [ region, control ].forEach( ( el ) => wrapper.appendChild( el ) );

        this.featuredWrapper = wrapper;
        this.featuredRegion = region;
        this.featuredControl = control;

        this.cardsWrapper.appendChild( fragment );

        control.addEventListener( 'click', this.handleFeaturedContent.bind( this ) );

    },

    handleFeaturedContent( e ) {

        const textClosed = 'More featured content';
        const textOpen = 'Less featured content';

        const didExpand = this.featuredRegion.classList.toggle( 'expanded' );

        if ( didExpand ) {

            this.featuredControl.textContent = textOpen;
            this.featuredControl.setAttribute( 'aria-expanded', true );
            this.featuredRegion.focus();

        }
        else {

            this.featuredControl.textContent = textClosed;
            this.featuredControl.setAttribute( 'aria-expanded', false );

        }

    }

} );
